<template>
  <article
    :style="{ backgroundColor: item.backgroundColor || undefined }"
    class="xs:px-16 relative overflow-hidden rounded-lg bg-[#013F7C] px-11 py-3.5 text-center"
  >
    <div class="absolute inset-0">
      <div
        class="absolute -bottom-[26px] -left-[38px] xl:-bottom-4 xl:-left-[50px] xl:flex"
      >
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="object-none"
        />
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="relative top-[50px] -ml-[32px] hidden object-none xl:block"
        />
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="-ml-[32px] hidden object-none xl:block"
        />
      </div>
      <div
        class="absolute -right-[48px] -top-7 xl:-bottom-4 xl:-right-[40px] xl:top-auto xl:flex"
      >
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="object-none"
        />
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="relative top-[50px] -mr-[32px] hidden object-none xl:block"
        />
        <img
          src="@valta/assets/images/banner-img-bg.svg?url"
          alt=""
          class="hidden object-none xl:block"
        />
      </div>
    </div>
    <div
      :style="{ color: item.titleColor || undefined }"
      class="relative mx-auto space-y-1 text-white lg:max-w-3xl xl:max-w-4xl 2xl:max-w-6xl"
    >
      <div
        class="text-4 font-semibold xl:leading-6"
        v-html="$sanitize(item.title)"
      />
      <p
        v-if="item.description"
        class="text-[10px] font-light sm:text-xs"
        v-html="$sanitize(item.description)"
      />
    </div>
    <Btn
      v-if="item.buttonHreff"
      :to="item.buttonHreff"
      :style="{
        backgroundColor: props.item.buttonColor || undefined,
        color: props.item.buttonTextColor || undefined,
      }"
      class="text-4 shadow-container relative mx-auto mt-3 inline-flex h-8 items-center rounded-lg bg-[#4A7EB1] px-4 font-medium text-white xl:absolute xl:right-[75px] xl:top-1/2 xl:m-0 xl:-translate-y-1/2"
    >
      {{ item.buttonText || 'Узнать подробности' }}
    </Btn>
    <Btn
      :style="{ color: props.item.buttonColor || undefined }"
      class="absolute right-2 top-2 text-[#4A7EB1] xl:right-9 xl:top-1/2 xl:-translate-y-1/2"
      @click="close"
    >
      <Icon name="close-round-duotone" class="h-6 w-6" />
    </Btn>
  </article>
</template>

<script setup lang="ts">
import type { CrossBanner } from '@/openapi_fetch'

const props = defineProps<{
  item: CrossBanner
}>()
const emit = defineEmits(['close'])

const { $sanitize } = useNuxtApp()

function close() {
  emit('close', props.item)
}
</script>
